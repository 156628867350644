// @import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
//@import '~@fortawesome/fontawesome-free/css/all.min.css';
//@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
//@import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
// @import '~admin-lte/dist/css/adminlte.css';

//@import 'ngx-toastr/toastr';
@import '../src/assets/css/bootstrap.css';
@import 'ngx-toastr/toastr';
//@import '../src/assets/css/adminlte.css';

/*
Los colores del brand estan como 'primary' , 'primary-dark' , 'secondary' ,'tertiary'
*/
:root {
  --blue: #0077ba;
  --blue-dark: #005b97;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #E04A4A;
  --orange: #fd7e14;
  --yellow: #f29525;
  --green: #4FB62B;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #999999;/*6c757d*/
  --gray-light: #BABABA;
  --gray-medium: #d8d8d8;
  --gray-dark: #343a40;
  --primary: #0147BA;
  --primary-op50: #0148ba80;
  --primary-op80: #0148bac7;
  --primary-dark: #013894;
  --primary-darker: #013081;
  --secondary: #666666;/*6c757d*/
  --tertiary: #f29525;
  --success: #4FB62B;
  --info: #17a2b8;
  --warning: #f29525;
  --danger: #E04A4A;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat" , "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

$primary: var(--primary);
$secondary: var(--secondary);
$success: var(--success);
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;

body {
  font-family: "Montserrat" , "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;
    font-size: 0.8rem !important;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.user-menu > .nav-link {
    cursor: pointer;
}

.user-menu > .nav-link:after {
    content: none;
}

.user-menu > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
    border-bottom: 1px solid #495057;
    border-top: 1px solid #dee2e6;
    padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
    display: block;
    clear: both;
    content: '';
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-body a {
        background: #ffffff !important;
        color: #495057 !important;
    }
}

.user-menu > .dropdown-menu > .user-footer {
    background-color: #f8f9fa;
    padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
    display: block;
    clear: both;
    content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #6c757d;
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f8f9fa;
    }
}

.user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2.1rem;
    margin-right: 10px;
    margin-top: -2px;
    width: 2.1rem;
}

@media (min-width: 576px) {
    .user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-right: 0.4rem;
        margin-top: -8px;
    }
}

.user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.content {
    //padding: 0 0.5rem;
}

.sidebar-collapse .brand-image-large {
    display: none ;
}
.sidebar-open .brand-image-small {
    display: none ;
}
.main-sidebar:hover .brand-image-small{
    display: none ;

}
.main-sidebar:hover .brand-image-large{
    display: inline ;
}

//Para corregir template

.navbar-dark{
    border-width: 0 !important;
}

//Para proposito general

.cursor-pointer{
    cursor: pointer;
}

//fix input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//media queries para botones block

@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;

    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}

// Estilos app telepagos v3

$border-radius : 0.3125rem;
$box-shadow : 0px 0px 30px rgba(0, 0, 0, 0.07);
$gray-filter: invert(85%) sepia(13%) saturate(13%) hue-rotate(34deg) brightness(89%) contrast(83%);
$primary-filter: invert(19%) sepia(90%) saturate(2358%) hue-rotate(210deg) brightness(90%) contrast(108%);

.content-wrapper{
  background-color: unset !important;
}

.card , .small-box {
    border-radius: 0.3125rem;
    box-shadow: $box-shadow;
}

.small-box .small-box-footer{
    border-radius:  0 0 $border-radius $border-radius;
}

.btn {
    border-radius: 0.3125rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
}

.btn.btn-padded {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
}

.custom-dropdown{
  padding: 1.8rem;
  .option{
    font-weight: 600 !important;
    font-size: 0.9rem;
  }
}

.user-menu>.dropdown-menu  {
  border-radius: 0 0 0 $border-radius !important;
  box-shadow: $box-shadow;
  width: 19rem;
  li{
    border-radius: 0 0 0 0.23rem !important;
    overflow: hidden;
  }
}

.dropdown-menu {
  top: 2.5rem;
  box-shadow: $box-shadow;
  &.dropdown-menu-right{
    right: -0.56rem;
  }
}
[class*=sidebar-light-] .sidebar a {
  color: var(--gray-light) !important;
  p{
    color: var(--primary) !important ;
  }
}
.nav-sidebar .nav-link.active {
  background-color: #ffffff !important ;
  i{
    color: var(--primary) !important ;
  }
  box-shadow: none !important;
}

.separation-line {
  height: 1px;

  &.white{
    background-color: #fff;
    opacity: .3;
  }
  &.secondary{
    background-color: var(--secondary);
    opacity: .3;
  }
}

.sidebar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500
}

.plataforma-web{
  color: var(--secondary);
  display: none;
  overflow: hidden !important;
  white-space: nowrap !important;
  ul{
    display: block;
    padding-left: 1rem;
    padding-bottom: 0.1rem;
  }
}
.sidebar-open{
  .plataforma-web{
    display: block;
  }
}
.sidebar-collapse{
  .main-sidebar:hover{
    .plataforma-web{
      display: block;
    }
  }
}

.navbar-white {
  background-color: unset;
  color: var(--primary) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--primary) !important;
}

.main-footer {
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
  background-color: unset;
}

.main-sidebar{
  box-shadow: $box-shadow;
  .nav-link{
    &.active{
      .sidebar-icon-custom {
        background-color: var(--primary);
      }
    }
  }

}

// Sidebar original TP

// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
// [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link {
//   background-color: unset;
// }
// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link:not(.active) i,
// [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link:not(.active) i{
//   color: var(--primary);
// }
// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link:not(.active),
// [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link:not(.active) {
//   .sidebar-icon-custom{
//     background-color: var(--primary );
//   }
// }

//Sidebar sin iconos y estatico

// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link{
//   background-color: var(--secondary);
// }
// [class*="sidebar-light-"] .nav-sidebar > .nav-item{
//   border-radius: 0px;
// }

// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link.active div.icon{
//   background-color: var(--primary);
// }
// // [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link:not(.active),
// // [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link:not(.active) {
// //   .sidebar-icon-custom{
// //     background-color: var(--primary );
// //   }
// // }

.btn-anchor{
  background-color: unset;
  border: unset;
  &-primary{
    color: var(--primary);
    &:hover{
      color: var(--primary-dark)
    }

    &[disabled = true],&:disabled{
      color: var(--secondary);
      opacity: 0.7;
      cursor: not-allowed;
      &:hover{
        color: var(--secondary);
      }
    }

  }
}

.btn-paginacion{
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  border: none;
  background-color: #EAEAEA;
}

.fix-padding{
  padding: .75rem 1.25rem;
}
.fix-padding-left{
  padding-left: 1.25rem;
}

.bg-white{
  background-color: #ffffff;
}

.height-full{
    min-height: calc(100vh - (3.5rem + 1px) - (3.5rem + 1px));
}

.sidebar-icon-position{
  transform: translate(.2rem,.2rem);
  position: absolute;
}

.sidebar-icon-custom{
  width: 1rem;
  height: 1rem;
  //background-color: var(--gray-light);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  -webkit-mask-size:contain; /* OR cover OR fixed values */

  -webkit-mask-position:center;
  -webkit-mask-repeat:no-repeat;

  mask-size:contain; /* OR cover OR fixed values */

  mask-position:center;
  mask-repeat:no-repeat;


}

.flip-h{
  &::before{
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    display: inline-block;
  }
}

//Custom Toast
.toast-custom-position {
  bottom: 2.5rem;
  right: 0;
  width: 100%;
}
.toast-container.toast-custom-position .ngx-custom-toastr {
  width: 360px;
  margin-left: auto;
  margin-right: auto;
}
.ngx-custom-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 1.25rem 1.25rem 0.75rem 3.125rem !important;
  width: 360px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  pointer-events: auto;
  box-shadow: $box-shadow !important;
  border-radius: $border-radius !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  .toast-message {
    width: 275px;
    padding-bottom: 5px;
  }
  .toast-close-button{
    top: -1.375rem;
    opacity: .8;
    font-size: 1.875rem;
    font-weight: 300;
    align-self: flex-start !important;
  }
}
.ngx-custom-toastr:hover {
  opacity: 1;
  cursor: pointer;
}
.toast-custom-title {
  font-weight: bold;
}
.toast-custom-message {
  word-wrap: break-word;
}
.toast-custom-message a:hover {
  text-decoration: underline;
}
.ngx-custom-toastr.toast-success{
  background-color: var(--success) !important
}
.ngx-custom-toastr.toast-error{
  background-color: var(--danger) !important
}

//scrollbar

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.overflow-scroll {
  overflow: scroll !important;

  &::-webkit-scrollbar-track
  {
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  border-radius: .5rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar
  {
  width: .7rem;
  height: .7rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: .5rem;
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  background-color: #555;
  }
}
.overflow-scroll-y {
  overflow-y: scroll !important;

  &::-webkit-scrollbar-track
  {
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  border-radius: .5rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar
  {
  width: .7rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: .5rem;
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  background-color: #555;
  }
}

.overflow-scroll-x {
  overflow-x: scroll !important;

  &::-webkit-scrollbar-track
  {
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  border-radius: .5rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar
  {
  height: .7rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: .5rem;
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  background-color: #555;
  }
}

//tabla custom sin bordes

.table-borderless{
  td {
    border: none;
  }
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

//striped list
.list-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format("truetype");
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
    font-weight: 900;
    font-style: italic;
}

// Mixins p/ Media queries responsive
@mixin row-to-column-reverse($minus-height){
  padding: 2rem 0 1rem 0;
  flex-direction: column-reverse;
  height: calc(100vh - $minus-height);
}

// MEDIA QUERIES RESPONSIVE

@media (max-width: 767px) {
  //brand logo
  .brand-link{
    display: none !important;
  }
  .plataforma-web{
    display: none !important;
  }

  //comportamiento sidebar
  .main-sidebar {
    &,
    &::before {
      transition: margin-right .3s ease-in-out, width .3s ease-in-out;
    }
    .sidebar-open & {
      &,
      &::before {
        margin-right: 180px;
        // margin-right: 220px;
        margin-left: auto;
      }
    }
  }
  .layout-fixed {
    .main-sidebar {
      bottom: 0;
      float: none;
      right: -220px;
      left: auto;
      position: fixed;
      top: 0;
    }

  }
  //seccion movimientos
  #movimientos-descargar-xls{
    span{
      display: none;
    }
    i{
      margin: 0 !important;
    }
  }
  #movimientos-descargar-pdf {
    span{
      display: none;
    }
    i{
      margin: 0 !important;
    }
  }

  //seccion cobrar
  .container-w-margins{
    width: 100% !important;
  }
  #card-saldo{
    background-color: transparent !important;
    box-shadow: none;
    padding: 0 !important;
    border-radius: 0;
    border-bottom: 1px solid #DDDDDD;
  }

  //seccion cobrar/pix
  #cobrar-pix-section{
    #cancelar-row-1{
      @include row-to-column-reverse(30rem);
    }
    #cancelar-row-2{
      @include row-to-column-reverse(48rem);
    }
    #mensaje-exito{
      text-align: center;
    }
  }

  //seccion cobrar/transferencia
  #transferencia{
    #cancelar-row{
      @include row-to-column-reverse(25rem);
    }
    #volver-row{
      @include row-to-column-reverse(39.5rem);
    }
    #datos-destinatario{
      border-radius: 0;
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 1.8rem;
    }
  }

  //seccion mi-cuenta
  #mi-cuenta{
    #volver-row{
      @include row-to-column-reverse(31rem);
    }
  }

  //seccion cambiar-contraseña
  #cambiar-password{
    #volver-row{
      @include row-to-column-reverse(39rem);
    }
  }

  //seccion soporte
  #soporte-header{
    flex-direction: column;
    h3:last-child{
      font-size: .8rem !important;
      margin-bottom: 0;
    }
  }


}

//header
.main-header {
  border-bottom: 1px solid #dee2e6;
  z-index: 1034;
  .navbar-nav#logo{
    display: block;
  }
  background-color: #FFF;
}

.main-header .nav-link {
  height: 2.5rem;
  position: relative;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  //padding: 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 1.25rem;
}

.bg-primary, .bg-secondary{
  color : #fff !important;
}

// @media (min-width: 768px) and (max-width: 991px) {

// }
// @media (min-width: 992px) and (max-width: 1199px) {

// }
// @media (min-width: 1200px) {

// }



// @media (max-width: 991px) {
//   .main-header .navbar-nav:first-child{
//     visibility: hidden;
//   }
//   .main-tabs{
//     visibility: visible !important;
//   }
// }

.card-nav{
  width: 100%;
  height: 100%;
  justify-content: space-around;
  padding: 2.18rem 1.8rem;
  text-align: center;
  font-weight: 700;
  word-break: keep-all !important;

  cursor: pointer;
  &:not(:last-child){
    margin-right: 2rem;
  }
  &.card-nav-disabled{
    background-color: var(--gray-medium);
    color: var(--gray);
    cursor: default;

    object{
      filter: brightness(40%);
    }
  }
  object{
    height: 1.25rem;
    margin-bottom: 1rem;
    transition: opacity ease-in-out .2s;
    opacity: .5;
    pointer-events: none;
  }

  &:not(.card-nav-disabled):hover{
    object{
      opacity: 1;
    }
  }
}
